import request from '@/utils/request'

// 查询热点话题 列表
export function listTopic( query ) {
  return request( {
    url : '/hot/topic/list',
    method : 'get',
    params : query
  } )
}

// 查询热点话题 详细
export function getTopic( id ) {
  return request( {
    url : '/hot/topic/' + id,
    method : 'get'
  } )
}

// 新增热点话题
export function addTopic( data ) {
  return request( {
    url : '/hot/topic',
    method : 'post',
    data : data
  } )
}

// 修改热点话题
export function updateTopic( data ) {
  return request( {
    url : '/hot/topic',
    method : 'put',
    data : data
  } )
}

// 删除热点话题
export function delTopic( id ) {
  return request( {
    url : '/hot/topic/' + id,
    method : 'delete'
  } )
}

export function openListTopic( query ) {
  return request( {
    url : '/openApi/hot/topic/list',
    method : 'get',
    params : query
  } )
}
