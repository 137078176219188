import request from '@/utils/request'

// 查询社区-问答 列表
export function listAnswer( query ) {
  return request( {
    url : '/answer/list',
    method : 'get',
    params : query
  } )
}

// 查询社区-问答 列表
export function listHistoryAnswer( query ) {
  return request( {
    url : '/answer/history/list',
    method : 'get',
    params : query
  } )
}

// 查询社区-问答 详细
export function getAnswer( id ) {
  return request( {
    url : '/answer/' + id,
    method : 'get'
  } )
}

// 新增社区-问答
export function addAnswer( data ) {
  return request( {
    url : '/answer',
    method : 'post',
    data : data
  } )
}

// 修改社区-问答
export function updateAnswer( data ) {
  return request( {
    url : '/answer',
    method : 'put',
    data : data
  } )
}

// 删除社区-问答
export function delAnswer( id ) {
  return request( {
    url : '/answer/' + id,
    method : 'delete'
  } )
}

// 逻辑删除
export function logicallyDelAnswer( id, reason = null ) {
  const param = {
    id : id,
    deletedReason : reason
  }
  return request( {
    url : '/answer/logicallyRemove',
    method : 'post',
    data : param
  } )
}

// 文章
export function getArticleList( query ) {
  return request( {
    url : '/experienceArticle/article/search',
    method : 'get',
    params : query
  } )
}
// 详情
export function getArticleInfo( query ) {
  return request( {
    url : '/experienceArticle/article/view',
    method : 'get',
    params : query
  } )
}

// 文章评论
export function addComment( data ) {
  return request( {
    url : '/experienceArticle/comment/add',
    method : 'post',
    data : data
  } )
}
// 查询评论
export function getComment( query ) {
  return request( {
    url : '/experienceArticle/comment/list',
    method : 'get',
    params : query
  } )
}


export function openListAnswer( query ) {
  return request( {
    url : '/openApi/answer/list',
    method : 'get',
    params : query
  } )
}
// 问答评论
export function getCommentList( query ) {
  return request( {
    url : '/openApi/answer/comment/list',
    method : 'get',
    params : query
  } )
}
export function postComment( query ) {
  return request( {
    url : '/comment',
    method : 'post',
    data : query
  } )
}
// 子评论
export function getCommentSubList( query ) {
  return request( {
    url : '/openApi/answer/comment/children/list',
    method : 'get',
    params : query
  } )
}

// 展示社区-问答
export function showAnswer( data ) {
  return request( {
    url : '/answer/show',
    method : 'put',
    data : data
  } )
}

// 查询评论
export function getUserHistoryComments( id ) {
  return request( {
    url : '/answer/comment/list/' + id,
    method : 'get'
  } )
}

// 删除评论
export function delCommon( id ) {
  return request( {
    url : '/comment/' + id,
    method : 'delete'
  } )
}

// 点击增加查看量
export function articleClick( data ) {
  return request( {
    url : `/experienceArticle/article/click?id=${data.id}`,
    method : 'put',
    data : data
  } )
}
