import request from '@/utils/request'

// 查询信息列表
export function listCaseList( query ) {
  return request( {
    url : '/openApi/case/list',
    method : 'get',
    params : query
  } )
}
// 查看详情
export function caseDetail( query ) {
  return request( {
    url : `/openApi/case/${query}`,
    method : 'get'
  } )
}
// 点击率
export function clickCase( query ) {
  return request( {
    url : `/openApi/case/click/${query}`,
    method : 'put'
  } )
}
