import request from '@/utils/request'

// 查询样例
export function listSamples( query ) {
  return request( {
    url : '/idis/query/samples',
    method : 'get',
    params : query
  } )
}

// 查询样例
export function listPrefixSamples( query ) {
  return request( {
    url : '/idis/query/prefixSamples',
    method : 'get',
    params : query
  } )
}

// 查询标识信息
export function queryIdentity( idisCode ) {
  return request( {
    url : '/idis/query/identity?code=' + encodeURIComponent( idisCode ),
    method : 'get'
  } )
}
